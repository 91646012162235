import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

import bgMd from '../../assets/images/lpChequeEspecialWhatsIsMd.png'
import bgLg from '../../assets/images/lpChequeEspecialWhatsIsLg.png'
import bgXl from '../../assets/images/lpChequeEspecialWhatsIsXl.png'

export const Section = styled.section`
  @media(min-width: ${breakpoints.md}) {
    background: url(${bgMd});
    min-height: 629px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.lg}) {
    background: url(${bgLg});
    min-height: 614px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.xl}) {
    background: url(${bgXl});
    min-height: 922px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${orange.extra};
  color: ${white};
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  border: none;
`
export const LinkButton = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${orange.extra};
  color: ${white};
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${white};
  }
`
