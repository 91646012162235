import React from 'react'
import Img from 'gatsby-image'
import { widths } from 'src/styles/breakpoints'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'
import pageQuery from '../../pageQuery'

import { Section, Button, LinkButton } from './style'

type componentPosp = {
  setIsOpen: Function;
}

const Hero = ({ setIsOpen }: componentPosp) => {
  const width = useWidth(300)
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>
              <span
                className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-orange--extra'
              >
                Cheque Especial{' '}
              </span>
              Precisou? O Inter tem!
            </h1>
            <p
              className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 mb-md-5'
            >
              O Inter é um Super App que tem tudo que você precisa. Até cheque especial.
            </p>
            {
              width >= widths.md ? (
                <Button
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: ' Abra sua Conta Digital',
                    section_name: 'Cheque Especial Precisou? O Inter tem!',
                  })
                  }}
                >
                  Abra sua Conta Digital
                </Button>
              ) : (
                <LinkButton
                  href='https://intergo.app/41553999'
                  target='_blank'
                  onClick={
                  () => sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: ' Abra sua Conta Digital',
                    section_name: 'Cheque Especial Precisou? O Inter tem!',
                  })
                }
                >
                  Abra sua Conta Digital
                </LinkButton>
              )
            }
          </div>
          {
            width >= widths.md && (
              <div className='col-md-6 col-lg-5'>
                <Img
                  fluid={data.hero.fluid}
                  alt='Tela de celular mostrando a área de cheque especial no Superapp Inter'
                />
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default Hero
