import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const BlogCard = styled.a`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      max-width: 100% !important;
    }
  }
`

export const BlogContentCard = styled.div`
  display: block;
  position: relative;
  background-color: white;
  border-radius: 10px 10px 0 0;
  top: -15px;
  padding: 16px 24px;
  @media ${device.tablet} {
    padding: 16px 16px;
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      max-width: 100%;
    }
  }
`
export const PrimaryCategory = styled.span`
  padding: 2px 4px;
  background-color: ${orange.extra};
  border-radius: 4px;
`

export const SecondaryCategory = styled.span`
  padding: 2px 4px;
  background-color: ${grayscale[100]};
  border-radius: 4px;
`

export const ContentBlock = styled.div`
  @media ${device.desktopLG} {
    height: 184px;
  }
`
