import React from 'react'

// Components
import FAQ from 'src/components/Faq'

// Data
import pageContext from '../../pageContext.json'

// Styles
import { Section } from './style'

const FaqInterOne = () => {
    return (
      <Section className='pt-3 py-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-grayscale--500 text-center mb-4'>Dúvidas frequentes</h3>
              <FAQ
                answerData={pageContext.structuredData.faq}
                columns={{ xl: 1, lg: 1 }}
                searchBg='#ffffff'
              />
            </div>
          </div>
        </div>
      </Section>
    )
}

export default FaqInterOne
