import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import Hero from './sections/hero/_index'
import WhatsIs from './sections/whatsIs/_index'
import TheInterSimplify from './sections/the-inter-simplify/_index'
import Blog from './sections/blog/_index'
import Faq from './sections/faq/_index'

import qrCode from './assets/images/qrcode-cheque-epecial.jpeg'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const ChequeEspecial = () => {
  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        customOrigin='lp Cheque Especial'
        closeModal={() => setIsOpen(false)}
        deepLink='https://intergo.app/41553999'
        qrBaixeApp={qrCode}
        dataLayer={{
          section_name: 'Abra agora sua Conta Digital',
          section: 'm_0',
          element_action: 'submit',
          element_name: 'continuar',
        }}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {modal}
        <Hero setIsOpen={setIsOpen} />
        <WhatsIs setIsOpen={setIsOpen} />
        <TheInterSimplify setIsOpen={setIsOpen} />
        <Blog />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ChequeEspecial
