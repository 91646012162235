import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: {originalName: { regex: "/lpCheckEspecialHero/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blog1: imageSharp(fluid: {originalName: { regex: "/lpChequeEspecialBlog1/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blog2: imageSharp(fluid: {originalName: { regex: "/lpChequeEspecialBlog2/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blog3: imageSharp(fluid: {originalName: { regex: "/lpChequeEspecialBlog3/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)
  return pageQuery
}

export default PageQuery
