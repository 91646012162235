import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: ${orange.extra};
  min-height: 630px;
  display: flex;
  align-items: center;


  @media (min-width: ${breakpoints.md}) {
    min-height: 352px
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 388px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 448px;
  }
`
export const Card = styled.div`
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  background: ${white};

  @media (min-width: ${breakpoints.md}) {
    padding: 8px;
    min-height: 283px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 267px;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 20px;
    min-height: 321px;
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${white};
  border-radius: 8px;
  border: none;
  color: ${orange.extra};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 24px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 247px;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 316px;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 379px;
  }
`
export const LinkButton = styled.a`
  width: 100%;
  height: 48px;
  background-color: ${white};
  border-radius: 8px;
  border: none;
  color: ${orange.extra};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${orange.extra};
  }
`
