import React from 'react'
import Img from 'gatsby-image'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import { widths } from 'src/styles/breakpoints'

import usePageQuery from '../../pageQuery'
import * as S from './style'
import { orange } from 'src/styles/colors'
import BlogArticleData from '../../assets/data/blog.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

interface IBlogArticle {
  img: {
    name: string;
    alt: string;
  };
  primaryCategory: string;
  secondaryCategory: string;
  date: string;
  title: string;
  description: string;
  articleUrl: string;
}

const AcompanheOBlogDaInterInvest = () => {
  const data = usePageQuery()
  const blogData: IBlogArticle[] = BlogArticleData
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)

  return (
    <section id='acompanhe-o-blog-da-inter-invest' className='bg-white py-5'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 mb-4 text-center'>
            <h2 className='fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>
              Conteúdos sobre educação financeira
            </h2>
          </div>
          {blogData.map((card: IBlogArticle) => (
            <S.BlogCard
              href={card.articleUrl} target='_blank' rel='noreferrer' className='col-12 col-md-4 d-block' key={card.img.name} onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: 'Conteúdos sobre educação financeira',
                element_action: 'click banner',
                element_name: card.title,
                redirect_url: card.articleUrl,
               })
              }}
            >
              <Img fluid={data[card.img.name].fluid} alt={card.img.alt} />
              <S.BlogContentCard>
                <div className='mb-3'>
                  <S.PrimaryCategory className='fs-12 lh-16 fw-700 text-white mr-3'>{card.primaryCategory}</S.PrimaryCategory>
                  {card.secondaryCategory && <S.SecondaryCategory className='fs-12 lh-16 fw-700 text-grayscale--500'>{card.secondaryCategory}</S.SecondaryCategory>}
                </div>
                <S.ContentBlock className='d-flex flex-column justify-content-between'>
                  <div>
                    <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
                    <p className='fs-12 lh-15 fw-700 text-grayscale--300 mb-3'>{card.date}</p>
                    {
                      width >= widths.lg && (
                        <p className='fs-16 lh-20 fw-400 text-grayscale--400 mb-2' dangerouslySetInnerHTML={{ __html: card.description }} />
                      )
                    }
                  </div>
                  {
                    width >= widths.lg && (
                      <div className='text-right'>
                        <span className='fs-14 lh-16 fw-400 text-orange--extra'>Leia mais
                          <ArrowRight height={24} width={24} color={orange.extra} />
                        </span>
                      </div>
                    )
                  }
                </S.ContentBlock>
              </S.BlogContentCard>
            </S.BlogCard>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AcompanheOBlogDaInterInvest
